// WIP

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const Input = forwardRef(
  ({ className, disabled, type, ...inputProps }, ref) => {
    function handleOnWheel(event) {
      if (type === 'number' && event.target === document.activeElement) {
        event.target.blur();
      }
    }

    return (
      <input
        ref={ref}
        className={classnames(
          'DSInput input-reset ba br3 b--default pl4 pr4 pt3 pb3 font-16',
          className
        )}
        disabled={disabled}
        // eslint-disable-line react/button-has-type
        type={type}
        onWheel={handleOnWheel}
        {...inputProps}
      />
    );
  }
);

Input.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

Input.defaultProps = {
  className: null,
  disabled: false
};

Input.displayName = 'DSInput';

export default Input;
