import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { IMAGE_MIME_TYPES } from 'dpl/shared/constants/mimeTypes';
import Upload from 'dpl/components/Upload';
import Icon from 'dpl/common/components/Icon';
import LoadingWrapper from 'dpl/components/LoadingWrapper';
import useDraggingOver from 'dpl/common/hooks/useDraggingOver';

export default function ImageUpload({
  accept,
  children,
  className,
  contentClassName,
  disabled,
  helperText,
  multiple,
  iconName,
  name,
  title,
  onChange,
  loading,
  inlineTitle
}) {
  const ref = useRef();
  const isDraggingOver = useDraggingOver(ref.current);
  if (children) {
    return (
      <LoadingWrapper isLoading={loading} className="transform-center-all">
        <div className={classnames('ImageUpload', className)}>
          {children}
          <Upload
            accept={accept}
            disabled={disabled}
            multiple={multiple}
            name={name}
            onChange={onChange}
          />
        </div>
      </LoadingWrapper>
    );
  }

  return (
    <LoadingWrapper isLoading={loading} className="transform-center-all">
      <div
        ref={ref}
        className={classnames(
          'ImageUpload bg-white h-100 ba br4 b--dashed b--default tc relative flex flex-column justify-center native-focus-outline',
          className,
          {
            'o-50': disabled,
            'bg-lightest-purple': isDraggingOver
          }
        )}
      >
        <div className="mv4 stone-700">
          {inlineTitle ? (
            <div className={classnames('flex items-center', contentClassName)}>
              <div
                className="inline-flex items-center justify-center br-100 bg-subtle-gray stone-700 mr2"
                style={{ height: '32px', width: '32px' }}
              >
                <Icon height="24px" name={iconName} width="24px" />
              </div>
              <p>{title}</p>
            </div>
          ) : (
            <Fragment>
              <div
                className="gd-icon inline-flex center items-center justify-center br-100 bg-subtle-gray stone-700"
                style={{ height: '32px', width: '32px' }}
              >
                <Icon height="24px" name={iconName} width="24px" />
              </div>
              <p className="lh-2">{title}</p>
            </Fragment>
          )}
          {helperText && <p className="f2 stone-700 mv3">{helperText}</p>}
        </div>
        <Upload
          accept={accept}
          disabled={disabled}
          multiple={multiple}
          name={name}
          onChange={onChange}
        />
      </div>
    </LoadingWrapper>
  );
}

ImageUpload.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  title: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  inlineTitle: PropTypes.bool,
  iconName: PropTypes.string
};

ImageUpload.defaultProps = {
  accept: IMAGE_MIME_TYPES.join(','),
  children: null,
  className: '',
  contentClassName: '',
  disabled: false,
  helperText: null,
  multiple: false,
  title: 'Add a photo',
  loading: false,
  inlineTitle: false,
  iconName: 'fetch-plus'
};
