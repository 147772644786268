import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../components/Icon';

function Checkbox({
  checked,
  disabled,
  id,
  name,
  onChange,
  required,
  label,
  value,
  ...checkboxProps
}) {
  const [isChecked, setIsChecked] = useState(Boolean(checked));

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div className="DSCheckbox">
      <label className="DSCheckbox__label flex items-center" htmlFor={id}>
        <input
          className="Checkbox__input"
          checked={isChecked}
          id={id}
          name={name}
          onChange={e =>
            onChange ? onChange(e) : setIsChecked(e.currentTarget.checked)
          }
          type="checkbox"
          value={value}
          disabled={disabled}
          {...checkboxProps}
        />
        <div
          className={classnames(
            'DSCheckbox__checkbox pv1 ph1 ba b--default br2',
            {
              'bg-white stone-700': !disabled,
              'bg-subtle-light-gray disabled': disabled
            }
          )}
        >
          <Icon
            className={!isChecked ? 'v-hidden' : ''}
            name="fetch-success"
            width="16px"
            height="16px"
          />
        </div>
        {label && (
          <p
            className={classnames('font-16 pl2 flex-auto', {
              secondary: !disabled,
              disabled
            })}
          >
            {label}
            {required && (
              <abbr
                className="rubber ml1"
                title="required"
                aria-label="required"
              >
                *
              </abbr>
            )}
          </p>
        )}
      </label>
    </div>
  );
}

Checkbox.displayName = 'DSCheckbox';

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ])
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  onChange: null,
  required: false,
  label: null,
  value: ''
};

export default Checkbox;
