import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'dpl/common/components/Icon';

export default function RadioButton({
  checked,
  id,
  name,
  onChange,
  title,
  subtitle,
  value,
  newDesignSystemStyles,
  variant
}) {
  const iconName = checked ? 'radio-on' : 'radio-off';
  return (
    <div className="RadioButton">
      <label htmlFor={id} className="flex items-center justify-between">
        <div>
          <p
            className={classnames({
              pl1: variant !== 'tabs',
              'font-16 secondary': newDesignSystemStyles
            })}
          >
            {title}
          </p>
          {subtitle && <p className="font-16 secondary">{subtitle}</p>}
        </div>
        <RadioButtonInput
          checked={checked}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          newDesignSystemStyles={newDesignSystemStyles}
          variant={variant}
        />
        {variant === 'tabs' && (
          <Icon name={`fetch-${iconName}`} className="blue-500" />
        )}
      </label>
    </div>
  );
}

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]),
  newDesignSystemStyles: PropTypes.bool,
  variant: PropTypes.string
};

RadioButton.defaultProps = {
  value: null,
  subtitle: null,
  newDesignSystemStyles: false,
  variant: null
};

export function RadioButtonInput({
  checked,
  id,
  name,
  onChange,
  value,
  newDesignSystemStyles,
  variant
}) {
  const iconName = checked ? 'radio-on' : 'radio-off';

  return (
    <div className="RadioButtonInput dib" data-test-id="RadioButtonInput">
      <input
        className="RadioButton__input"
        checked={checked}
        id={id}
        name={name}
        onChange={() => {}}
        onClick={onChange}
        type="radio"
        value={value}
      />
      {variant !== 'tabs' && (
        <Icon
          name={newDesignSystemStyles ? `fetch-${iconName}` : iconName}
          className={classnames({
            'blue-500': newDesignSystemStyles
          })}
        />
      )}
    </div>
  );
}

RadioButtonInput.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]),
  newDesignSystemStyles: PropTypes.bool,
  variant: PropTypes.string
};

RadioButtonInput.defaultProps = {
  value: null,
  newDesignSystemStyles: false,
  variant: null
};
